exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arbeitsbeispiele-js": () => import("./../../../src/pages/arbeitsbeispiele.js" /* webpackChunkName: "component---src-pages-arbeitsbeispiele-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leichtbau-js": () => import("./../../../src/pages/leichtbau.js" /* webpackChunkName: "component---src-pages-leichtbau-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-unternehmen-js": () => import("./../../../src/pages/unternehmen.js" /* webpackChunkName: "component---src-pages-unternehmen-js" */)
}

